<template>
  <div class="hello">
    <h1>Integer Visualizer</h1>
    <p>
      This tool shows three ways of encoding integers:
      <ul class="all-ways">
        <li>- only positive integers: unsigned</li>
        <li>- both positive and negative integers: sign and magnitude</li>
        <li>- both positive and negative integers: two's complement</li>
      </ul>
    </p>

    <div>
      <h3>
        Select encoding strategies to use:
      </h3>
      <ul class="encoding-strategies">
        <li :class="{active: encodings.unsigned}" @click="toggleStrategy('unsigned')">
          unsigned
        </li>
        <li :class="{active: encodings.signAndMagnitude}" @click="toggleStrategy('signAndMagnitude')">
          sign and magnitude
        </li>
        <li :class="{active: encodings.twosComplement}" @click="toggleStrategy('twosComplement')">
          two's complement
        </li>
      </ul>
    </div>

    <div>
      Number of bits: 
      <input type="number" v-model="numberOfBits" @change="changeNumberOfBits">
    </div>
    <div class="bit-container" :class="{sign: encodings.signAndMagnitude || encodings.twosComplement}">
      <button v-for="(bit, index) in bits" :key="index" @click="swapBit(index)">{{ bit }}</button>
    </div>
    <div v-show="encodings.unsigned">
      <h2>
        Unsigned Value Decoded:
      </h2>
      <Approach :steps="approach.unsigned" />
      <h3>
        result: 
        {{ unsignedValueDecoded }} <!-- <input type="number" v-model="unsignedValueDecoded">  -->
      </h3>

    </div>

    <div v-show="encodings.signAndMagnitude">
      <h2>
        Sign And Magnitude Value Decoded: 
      </h2>
      <Approach :steps="approach.signAndMagnitude" />
      <h3>
        result: {{ signAndMagnitudeDecodedSign }}
        {{ signAndMagnitudeDecodedMagnitude }} <!-- <input type="number" v-model="unsignedValueDecoded">  -->
      </h3>
    </div>

    <div v-show="encodings.twosComplement">
      <h2>
        Two's Complement Value Decoded:
      </h2>
      <Approach :steps="approach.twosComplement" />
      <h3>
        result: 
        {{ twoComplementValueDecoded }} <!-- <input type="number" v-model="unsignedValueDecoded">  -->
      </h3>
    </div>
  </div>
</template>

<script>
import Approach from "./Approach.vue"

export default {
  name: 'IntegerVisualizer',
  components: { Approach },
  created() {
    this.reset();
  },
  data() {
    return {
      encodings: {
        unsigned: false,
        twosComplement: false,
        signAndMagnitude: false,
      },
      approach: {
        unsigned: [],
        twosComplement: [],
        signAndMagnitude: [],
      },
      numberOfBits: 32,
      bits: [0, 1, 1, 0],
      unsignedValueDecoded: 0,
      twoComplementValueDecoded: 0,
      signAndMagnitudeDecodedSign: '+',
      signAndMagnitudeDecodedMagnitude: 0,
    }
  },
  methods: {
    swapBit(index) {
      this.bits[index] = this.bits[index]? 0 : 1;
      this.decode();
    },
    reset() {
      this.bits = new Array(this.numberOfBits).fill(0);
      this.decode();
    },
    decode() {
      this.decodeUnsignedValue();
      this.decodeTwosComplementValue();
      this.decodeSignAndMagnitudeValue();
    },
    changeNumberOfBits(event) {
      this.numberOfBits = Math.min(52, Math.max(0, this.numberOfBits));
      console.log(`Change numer of bits ${event} -> ${this.numberOfBits}`);
      const newBits = new Array(this.numberOfBits).fill(0);
      for (let i = 0; i < newBits.length; i ++) {
        if (i < this.bits.length) {
          newBits[i] = this.bits[i];
        }
      }
      this.bits = newBits;
      this.decode();
    },
    decodeUnsignedValue() {
      let powerOfTwo = 1;
      let resultValue = 0;
      const approach = []
      this.bits.forEach((bit, index) => {
        if (bit) {
          resultValue += powerOfTwo;
          approach.push(`<strong>+ ${powerOfTwo}</strong> (for 1${'0'.repeat(index)} = 2^${index})`);
        }
        powerOfTwo = 2 * powerOfTwo;
      })
      this.unsignedValueDecoded = resultValue;
      this.approach.unsigned = approach;
    },
    decodeTwosComplementValue() {
      let powerOfTwo = 1;
      let sum = 0;
      let complementSum = 0;
      const approach = []
      const approachComplement = []
      approach.push('The last bit (the most left one) is <span class="tag-sign">0</span> ==> we are going to sum power of two for all 1\'s like doing unsigned.');
      approachComplement.push('The last bit (the most left one) is <span class="tag-sign">1</span> ==> we are going to sum complements of powers of two for all 0\'s and then add 1.');

      this.bits.slice(0, -1).forEach((bit, index) => {
        if (bit) {
          sum += powerOfTwo;
          approach.push(`<strong>+ ${powerOfTwo}</strong> (for 1${'0'.repeat(index)} = 2^${index})`);
        } else {
          complementSum += powerOfTwo;
          approachComplement.push(`<strong>+ ${powerOfTwo}</strong> (for 0${'1'.repeat(index)} is complement to 2^${index})`);
        }
        powerOfTwo = 2 * powerOfTwo;
      });
      const lastBit = this.bits[this.numberOfBits-1]
      if (lastBit) {
        this.twoComplementValueDecoded = - (complementSum + 1);
        approachComplement.push(`<strong>+ 1</strong> (remember, add one on the end.`);
        this.approach.twosComplement = approachComplement;
      } else {
        this.twoComplementValueDecoded = sum;
        this.approach.twosComplement = approach;
      }
    },
    decodeSignAndMagnitudeValue() {
      let powerOfTwo = 1;
      let sum = 0;
      const approach = []

      const lastBit = this.bits[this.numberOfBits-1]
      if (lastBit) {
        approach.push('<strong>sign is -</strong> The last bit (the most left one) is <span class="tag-sign">1</span> ==> result is negative.');
      } else {
        approach.push('<strong>sign is +</strong> The last bit (the most left one) is <span class="tag-sign">0</span> ==> result is positive');
      }

      this.bits.slice(0, -1).forEach((bit, index) => {
        if (bit) {
          sum += powerOfTwo;
          approach.push(`<strong>+ ${powerOfTwo}</strong> (for 1${'0'.repeat(index)} = 2^${index})`);
        } 
        powerOfTwo = 2 * powerOfTwo;
      });
      
      this.signAndMagnitudeDecodedSign = lastBit? '-' : '+';
      this.signAndMagnitudeDecodedMagnitude = sum;
      this.approach.signAndMagnitude = approach;
    },
    toggleStrategy(strategy) {
      if (Object.hasOwn(this.encodings, strategy)) {
        this.encodings[strategy] = !this.encodings[strategy]
      }
    }
  },
}
</script>

<style>
h3 {
  margin: 40px 0 0;
}
ul.encoding-strategies {
  list-style-type: none;
  padding: 0;
}
ul.encoding-strategies > li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.bit-container {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.bit-container > button {
  width: 24px;
  text-align: center;
  height: 20px;
}


.bit-container > button:nth-child(8n) {
  margin-left: 10px;
}

.bit-container.sign > button:last-child {
  background-color: yellow;
}

.encoding-strategies > li {
  border: 1px solid black;
  padding: 2px;
  background-color: lightgray;
}

.encoding-strategies > li.active {
  background-color: rgb(64, 255, 47);
}

.tag-sign {
  background: yellow;
}

.all-ways {
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
}
</style>
