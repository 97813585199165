<template>
    <h4 @click="toggleCollapse" v-if="collapse">show steps &#9662;</h4>
    <h4 @click="toggleCollapse" v-if="collapse == false">collapse steps &#9652;</h4>
    <div v-if="collapse == false" class="approach">
        <p v-for="(step, i) in steps" :key="i" v-html="step"></p>
    </div>
</template>

<script>
export default {
    name: 'computation-approach',
    props: ['steps'],
    data() {
        return {
            collapse: true,
        }
    },
    methods: {
        toggleCollapse() {
            this.collapse = ! this.collapse;
        } 
    },
}
</script>

<style>

</style>